button {
  border: none;
  background: none;
  // pointer-events: all;
  cursor: pointer;
  color: inherit;
  padding: 0;
  outline: none;
}

.def {
  color: var(--color-white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  @include fixel_16_sbl;
  text-transform: capitalize;
  position: relative;

  .txt {
    pointer-events: none;
  }

  &.wt {
    &:hover {
      color: var(--color-primary);
    }
  }
  &.red {
    background-color: #8a1934;

    &:hover {
      background-color: var(--color-destructive-2);
    }
  }

  &.pr {
    // color: var(--color-black);
    // background-color: #a7e64c;
    transition: none;
    background: var(--button-gradient);

    // &:hover {
    //   // background-color: var(--color-primary);
    // }

    &[disabled] {
      opacity: 0.4;
      // color: #00000066;
      cursor: not-allowed;
    }
  }
  &.tr {
    background-color: var(--color-tertiary);

    &:hover {
      background-color: #2d274d;
    }
  }
  &.sec {
    background-color: var(--color-secondary);

    &:hover {
      background-color: #9747ff;
    }
  }

  &.stroke {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background: var(--button-gradient);
    }

    &::before {
      clip-path: url("#funtico_gradient");
    }

    &::after {
      opacity: 0;
      transition: 0.3s ease-in-out;
    }

    @include min_1025 {
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .txt {
    position: relative;
    z-index: 3;
  }

  &.gradient {
    background: var(--button-gradient);
    transition: none;
  }
  &.pink {
    background: radial-gradient(197.79% 141.42% at 0% 0%, #f12ef5 0%, #8749ed 59.38%, #8749ed 80.21%, #ba24d2 100%);
  }
  &.grey {
    background: radial-gradient(
      166.44% 141.42% at 0% 0%,
      rgba(184, 51, 246, 0.4) 0%,
      rgba(184, 51, 246, 0.4) 13.02%,
      rgba(104, 80, 255, 0.4) 61.65%,
      rgba(104, 80, 255, 0.4) 70.81%,
      rgba(41, 88, 255, 0.4) 97.92%
    );
  }

  &.buy_button {
    padding: 3px;
    .event {
      flex: 1;
      border-radius: $__r-14;
      padding: 9px;
      background-color: #181528;
      text-align: center;
      transition: background-color 0.3s ease-in-out;
      position: relative;
      cursor: pointer;

      @include min_1025 {
        &:hover {
          background-color: transparent;
        }
      }
    }

    .buy {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .add {
      flex: none;
      align-self: stretch;
      aspect-ratio: 1/1;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        background-color: #f12ef52e;
      }

      svg {
        width: min_max(20, 24);
        height: auto;
        path {
          fill: var(--color-white);
        }
      }
    }
  }

  &.border {
    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: $__r-16;
      background-color: #0f0d1a;
      transition: background-color 0.3s ease-in-out;
      pointer-events: none;

      @include max_1025 {
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
      }
    }

    @include min_1025 {
      &:hover {
        &::before {
          background-color: transparent;
        }
      }
    }
  }

  &.type-40 {
    padding: $__16 $__40;
  }
  &.type-32 {
    padding: $__12 $__32;
  }
  &.type-24 {
    // padding: 8px 24px;
    padding: $__8 $__24;
  }
}

.load_more {
  gap: $__10;
  padding: $__8 0;

  .icon {
    width: $__24;

    svg {
      width: 100%;
      height: auto;
      fill: var(--color-white);
      transition: 0.3s ease-in-out;
    }
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;

    .icon {
      animation: 0.5s linear infinite slidein;
    }
  }

  @keyframes slidein {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @include min_1025 {
    &:hover {
      color: var(--color-primary);

      svg {
        fill: var(--color-primary);
      }
    }
  }
}
