section.policy {
  .section_content {
    @include max_1025 {
      .content {
        margin-top: $__34;
      }
    }
  }

  .content {
    h2 {
      font-size: min_max(18, 28);
    }
    * {
      margin-top: 1em;
    }
  }
}
