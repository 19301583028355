.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: var(--color-page-bg);
  background: #0f0d1a;
  z-index: 10005;
  display: grid;
  place-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}
