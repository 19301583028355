.pop_up {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-95);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  z-index: 10001;

  &:not(.active) * {
    pointer-events: none !important;
  }

  @include min_1025 {
    background-color: #04030acc;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }
  .overflow {
    width: 100%;
    overflow: scroll;
    overflow-x: visible;
    @include no-scrollbar;
    max-height: 100%;

    @include min_1025 {
      padding: 5vh 0;
    }
  }

  .content {
    width: 100%;
    max-width: 768px;
    background-color: var(--color-95);
    border-radius: $__r-16;
    gap: $__46;
    padding: 60px 14px;
    overflow: hidden;

    @include min_1025 {
      width: min_max(550, 660);
      padding: $__80;
      position: relative;
    }

    .close {
      position: absolute;
      pointer-events: all;
      top: 20px;
      right: 14px;

      @include min_1025 {
        top: $__40;
        right: $__40;
      }
    }

    &_data {
      gap: 24px;
    }

    &.reg {
      .form {
        align-items: center;
        gap: 0;
      }
    }
  }

  .table {
    &_grid {
      gap: 24px;

      .grid {
        display: flex;
        flex-direction: column;
        gap: $__10;

        .row {
          display: flex;
          gap: $__24;

          .col {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.key {
              color: var(--color-neutral-purple);
            }
          }
        }
      }

      .line {
        width: 100%;
        display: flex;
        align-items: center;
        height: $__24;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: var(--color-8);
        }
      }
    }
  }

  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: $__16;
    width: 80%;

    .icon {
      width: $__100;
      height: $__100;
      background-color: #106b9d;
    }

    .t {
      text-transform: capitalize;

      @include max_1025 {
        font-size: 20px;
      }
    }
    // .s {
    // }
  }

  .form {
    gap: $__46;

    @include max_1025 {
      // width: 80%;
    }

    .submit {
      min-width: 100%;

      @include min_1025 {
        min-width: min_max(220, 250);
      }
    }

    .form_row {
      flex-wrap: wrap;
      gap: 14px;
    }
  }

  .or {
    gap: $__16;
    color: #84819c99;
    width: 100%;

    &::after,
    &::before {
      content: "";
      display: block;
      flex: 1;
      height: 2px;
      background-color: var(--color-8);
    }
  }

  .switch_form {
    gap: $__10;

    p {
      color: var(--color-neutral-purple);
    }
  }

  .corfirm_buttons {
    width: 100%;
    gap: $__24;

    &.double {
      button {
        flex: 1;
      }
    }
    &.sing {
      button {
        flex: 1;

        @include min_1025 {
          min-width: min_max(220, 250);
          flex: unset;
        }
      }
    }
  }

  .via_soc {
    .btns {
      width: 100%;
      gap: 14px;
      flex-wrap: wrap;
    }

    .link {
      flex: 1;

      @include max_1025 {
        min-width: 270px;
      }

      .icon {
        width: $__24;
        margin-right: $__10;

        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
