.scroll-bar {
  display: flex;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  width: 6px;
  border-radius: 20px;
  background-color: #14111f;
  height: 100%;
  right: 8px;
  top: 0;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    transform: scaleX(1.5);

    .scroll-track {
      opacity: 1;
      cursor: grab;
    }
  }

  &.is-dragging {
    cursor: grabbing;
    transform: scaleX(1.5);

    .scroll-track {
      opacity: 1;
      transition: none;
    }
  }

  .scroll-track {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    // background-color: var(--color-primary);
    background: var(--logo-gradient);
    border-radius: 16px;
    transition: transform 0.1s linear;
    opacity: 0.8;

    &:hover {
      cursor: grab;
    }
  }
}
