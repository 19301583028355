// ============  HOME =============== //

.page_pioneers {
  &.hidden {
    section:not(.intro) {
      opacity: 0;
      transform: translateY(50px);
    }
  }

  section:not(.intro) {
    transition: 0.5s ease-in-out;
  }

  .intro {
    margin-top: 0;
    @include max_1025 {
      .section_content {
        padding: 0;
        max-width: none;
      }
    }

    .navs {
      gap: $__24;
      width: 100%;
      padding: 0 14px;

      a {
        min-width: 250px;
      }
    }
    img {
      width: 100%;
      height: auto;
      // min-height: 250px;
      object-fit: cover;

      @include min_1025 {
        border-radius: 0 0 $__r-16 $__r-16;
        // aspect-ratio: 1608/600;
      }
    }
  }

  .information {
    flex-direction: column;
    align-items: center;
    background: #0f0d1a;

    .section_content.info {
      @include min_1025 {
        padding-left: min_max(40, 156);
        align-items: center;

        @include lg-2 {
          padding-left: min_max(40, 292);
        }
      }

      @include max_1025 {
        flex-direction: column;
        align-items: center;
      }

      .content {
        width: 100%;
      }
      h1 {
        text-transform: capitalize;

        @include max_1025 {
          font-size: 26px;
          text-align: center;
          order: -2;
        }
      }

      .text_content {
        width: 100%;
        text-align: center;
        display: contents;

        // button {
        //   text-transform: none;
        // }

        @include min_1025 {
          max-width: min_max(390, 520);
          text-align: left;
          display: block;

          .imp {
            max-width: 385px;
            // opacity: 0.7;
          }
        }
      }

      .description {
        margin-top: min_max(16, 32);
      }

      .icon {
        flex: none;
        width: 100%;
        margin-top: 16px;
        position: relative;
        z-index: 2;
        // max-height: 450px;
        opacity: 0;

        &.loaded {
          mix-blend-mode: lighten;
          opacity: 1;
        }

        @include min_1025 {
          margin: 0;
          max-width: none;
          aspect-ratio: 792/650;
          width: min_max(300, 792);
          // max-height: none;
          position: relative;
        }

        video {
          width: auto;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          &.hide {
            opacity: 0.001;
          }

          .loop {
            z-index: 2;
          }
        }

        @include max_1025 {
          order: -1;
          aspect-ratio: 888/592;
          width: 120%;

          .i {
            max-width: 90%;

            // img {
            //   // aspect-ratio: 4/3;
            // }
          }

          video {
            // width: 120%;
            width: 100%;
            height: auto;
            // max-width: 900px;
          }
        }
      }
    }

    .section_content.contact {
      .text_content {
        width: 100%;
        max-width: 520px;
        margin-top: min_max(60, 100);

        h2 {
          text-align: center;
          text-transform: capitalize;

          @include max_1025 {
            br {
              display: none;
            }
          }
        }
      }

      .form_content {
        .checkbox {
          width: 100%;
        }

        .select {
          .list {
            position: absolute;
            top: 100%;
            margin-top: $__8;
            z-index: 2;
          }
        }
      }
      .error_form {
        color: var(--color-destructive-3);
        text-align: center;
      }

      .buttons {
        gap: $__24;
        z-index: 1;

        @media (max-width: 500px) {
          flex-direction: column;
          align-items: center;

          button {
            width: 100%;
          }
        }

        .re_button {
          position: relative;
          padding: $__12 $__20;
          gap: $__16;
          border: 2px solid var(--color-7);
          flex: none;
          cursor: pointer;
          transition: 0.3s ease-in-out;

          &:hover {
            border-color: #433f57;
          }

          .i {
            width: 24px;

            svg {
              width: 100%;
              height: auto;
            }
          }

          .re {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.0001;
            z-index: 2;
            cursor: pointer;

            * {
              cursor: pointer;
            }
          }
        }

        .submit {
          // width: 100%;
          flex: 1;

          // @include min_1025 {
          //   max-width: 260px;
          // }
        }
      }

      .required {
        color: var(--color-4);
      }
    }
  }

  .rewards {
    @include min_1025 {
      .section_content {
        padding: 0 min_max(40, 156);

        .swiper-wrapper {
          display: flex;
          gap: min_max(20, 24);
        }

        @include lg-2 {
          padding: 0 min_max(40, 292);

          h2 {
            @include fixel_36;
          }
        }
      }
    }

    .slider {
      overflow: hidden;
      width: 100%;
      margin-top: min_max(16, 46);
    }

    .market_slide {
      padding: min_max(8, 16);
      background: rgba(120, 112, 150, 0.08);
      gap: $__14;
      border-radius: $__r-16;
      transition: background-color 0.3s ease-in-out;

      @include min_1025 {
        flex: 1;
        margin: 0 !important;
      }

      @include max_1025 {
        width: min_max_mob(200, 194);
      }

      .icon {
        flex: 1;

        @include min_1025 {
          // padding: 5px;
          border-radius: $__r-12;
        }

        img {
          aspect-ratio: 1/1;
          width: 100%;
          height: auto;
        }
      }

      .data {
        gap: $__6;

        .l {
          color: var(--color-neutral-purple);
        }

        .lim {
          // color: #8840e6;
          // color: #f8af22;

          background: radial-gradient(
            197.79% 141.42% at 0% 0%,
            #f12ef5 0%,
            #8749ed 59.38%,
            #8749ed 80.21%,
            #ba24d2 100%
          );
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          background-clip: text;
        }

        @include max_1025 {
          p {
            font-size: 12px;
          }
        }
      }
    }
    .text_content {
      text-align: center;
    }

    .swiper-scrollbar {
      margin-top: min_max(32, 46);
    }
  }

  .pre-join {
    .content {
      position: relative;
      width: 100%;
      z-index: 1;
      text-transform: capitalize;
      text-align: center;

      @include min_769 {
        min-height: min_max(265, 460);
      padding: 38px;

      }

      @include max_768 {
        flex-direction: column;
        gap: 24px;
        align-items: stretch;

        button {
          width: 100%;
        }
      }

      .bg {
        @include min_769 {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: -1;
          pointer-events: none;
          background: url("../img/pre-sign/join.jpg") center/cover repeat;
        }

        @include max_768 {
          background: url("../img/pre-sign/join_mob_sm.jpg") right/cover repeat;
          width: 100%;
          aspect-ratio: 675/460;
          border-radius: 16px;
          overflow: hidden;
        }

        @media (max-width: 640px) {
          background: url("../img/pre-sign/join_mob_xs.jpg") right/cover repeat;
          aspect-ratio: 332/480;
        }

        // img {
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        //   object-position: bottom left;
        // }
      }

      // .link {
      //   width: 100%;
      //   margin-top: min_max(24, 46);
      //   max-width: 280px;

      //   @include xs {
      //     min-width: min_max(200, 250);
      //   }
      // }
    }
  }

  .trailers {
    // .section_content {
    // }

    h2 {
      text-align: center;

      @include min_1025 {
        @include fixel_36;
      }
    }

    .videos {
      gap: min_max(16, 24);
      margin-top: min_max(16, 46);
      flex-direction: column;
      @include xs {
        flex-direction: row;
      }
    }

    .video {
      flex: 1;
      cursor: pointer;

      .icon {
        position: relative;
        display: flex;
        aspect-ratio: 656/370;
        @include xs {
          aspect-ratio: 656/417;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          pointer-events: none;
          background-color: var(--color-page-bg);
          opacity: 0.3;
          transition: 0.3s ease-in-out;
        }
      }

      .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: min_max(60, 80);
        z-index: 2;

        svg {
          width: 100%;
          height: auto;
          fill: var(--color-white);
          transition: 0.3s ease-in-out;
        }
      }

      p {
        margin-top: min_max(6, 16);
        text-transform: capitalize;
        text-align: center;

        span {
          color: var(--color-primary);
        }
      }

      @include min_1025 {
        &:hover {
          .icon {
            &::before {
              opacity: 0;
            }

            svg {
              transform: scale(1.1);
              opacity: 0.8;
            }
          }
        }
      }
    }

    .vision_link {
      margin-top: min_max(60, 100);
      width: 100%;

      button {
        width: 100%;
      }

      @include xs {
        width: auto;
      }
    }
  }

  .message {
    .text_content {
      text-align: center;
      width: 100%;
      max-width: min_max(520, 792);
    }

    h1 {
      text-transform: capitalize;
    }

    p {
      max-width: min_max(450, 520);
      // text-transform: capitalize;

      &.new,
      &.confirmed {
        color: #a7e64c;
        font-family: var(--fixel_med);
        font-weight: 500;
      }
    }
  }

  .pop_up {
    background: #04030af2;
    .trailer {
      padding: min_max(60, 80);
      background-color: transparent;
      width: 100%;
      max-width: 1240px;
      gap: unset;

      @include max_1025 {
        padding: 0;

        p {
          padding: 0 14px;
        }
      }

      iframe {
        border: none;
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
      }

      p {
        text-transform: capitalize;

        span {
          color: var(--color-primary);
        }
      }
    }
  }
}

// ============  VISION =============== //
.page_vision {
  @include max_1025 {
    gap: 60px;

    .visions .section_content {
      max-width: none;
    }
  }

  [data-fade] {
    transform: translateY(50px);
    opacity: 0;
  }
}

.vision_intro {
  overflow: visible;
  .text_content {
    width: 100%;
    text-align: center;

    .title {
      overflow: hidden;
    }

    @include min_1025 {
      max-width: min_max(500, 792);
      p {
        width: 100%;
        max-width: min_max(450, 520);
      }
    }

    h1 {
      text-transform: uppercase;
      font-size: min_max(28, 60);
      padding-bottom: min_max(12, 6);
    }

    h2 {
      margin-top: min_max(24, 60);
      text-transform: capitalize;
    }

    .line {
      width: 100%;
      height: 3px;
      border-radius: $__r-8;
      background: var(--logo-gradient);
    }
  }
}

.visions {
  overflow: visible;
  .section_content {
    gap: min_max(60, 100);
  }

  .vision {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &:nth-child(even) {
      .icon {
        transform: rotateY(-180deg);

        img {
          transform: rotateY(-180deg);
        }
      }
    }

    .icon {
      width: 100%;
      // clip-path: url(#vision_clip);
      position: relative;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      // clip-path: polygon(0% 0%, 100% 16.5%, 100% 83.5%, 0% 100%);
      // min-height: 330px;
      height: min_max(330, 600);

      span {
        align-items: flex-end;
      }
      img {
        width: 100%;
        height: 110%;
        // min-height: 110%;
        object-fit: cover;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        height: min_max(150, 280);
        background: linear-gradient(180deg, rgba(15, 13, 26, 0) 0%, #0f0d1a 100%);
        pointer-events: none;
        transition: 1s ease;
      }

      &.active {
        &::after {
          opacity: 0.96;
        }
      }
    }

    @include max_1025 {
      &:nth-child(3) {
        .icon img {
          object-position: right;
        }
      }
    }

    @include min_1025 {
      &:nth-child(3n + 1) {
        .text_content {
          align-self: center;
          text-align: center;
        }
      }
      &:nth-child(3n + 3) {
        .text_content {
          align-self: flex-end;
          text-align: right;
        }
      }
    }
  }

  .text_content {
    margin-top: calc(min_max(60, 100) * -1);
    z-index: 2;
    position: relative;

    @include max_1025 {
      align-self: center;
      padding: 0 24px;
      text-align: center;
    }

    .content {
      width: 100%;
      max-width: min_max(450, 520);
    }
  }
}

.copyright {
  background-color: var(--color-page-bg);
  display: flex;
  justify-content: center;
  padding-top: min_max(60, 120);
  padding-bottom: min_max(60, 80);

  .socials {
    width: 200px;

    @include max_1025 {
      justify-content: center;
      gap: 24px;
    }

    @include xs {
      width: auto;
    }
  }

  nav {
    display: flex;
    gap: $__24;

    @include max_1025 {
      flex-direction: column;
      align-items: center;
      gap: 14px;
      margin-bottom: 20px;
    }

    a {
      @include min_1025 {
        color: var(--color-neutral-purple);
        transition: 0.3s ease-in-out;

        &:hover {
          color: white;
        }
      }

      &[aria-current] {
        color: white;
      }
    }
  }
  .right {
    color: var(--color-7);
  }
}
