// Normalize
@import "modern-normalize/modern-normalize.css";
@import "swiper/css";

@import "./shared";
@import "./components";

//Layout
@import "./layout/preloader";
@import "./layout/popups";
@import "./layout/scrollbar";

@import "./pages/sign";
@import "./pages/policy";
@import "./pages/404";

main {
  display: flex;
  flex-direction: column;
  gap: min_max(40, 100);
  // background-color: var(--color-page-bg);
  background-color: #0f0d1a;
  min-height: 100vh;
  padding: min_max(80, 136) 0;
  position: relative;
  overflow: hidden;

  @include min_1025 {
    &:not(.page_privacy_policy, .page_terms_and_conditions) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: url("../img/back/main_back.png") center top/100% repeat;
        pointer-events: none;
        z-index: 3;
      }
    }
  }

  &.page_pioneers {
    padding-top: 0;
  }
}
