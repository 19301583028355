section.not_found {
  align-items: center;
  // background-color: var(--color-page-bg);
  background: #0f0d1a;
  flex: 1;

  .def {
    min-width: min_max(230, 250);
  }
}
