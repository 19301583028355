.form_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  width: 100%;

  @include min_1025 {
    gap: $__24;
  }

  .buttons {
    margin-top: 8px;

    @include min_1025 {
      width: 100%;

      margin-top: $__20;
    }
    .cleare {
      gap: $__10;
    }
  }
}

.underline {
  background: var(--color-8);
  height: 2px;
  width: 100%;
  border-radius: 12px;
}

label {
  @include fixel_16_md;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $__8;
  width: 100%;

  .label_description {
    color: var(--color-neutral-purple);
    order: 3;

    @include max_1025 {
      font-size: 12px;
    }
  }

  .top_tip {
    position: relative;
    gap: 6px;
    align-self: flex-start;

    @include max_1025 {
      flex-wrap: wrap;
    }

    .icon {
      width: min_max(16, 20);
      aspect-ratio: 1;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z' fill='white'/%3E%3Cpath d='M10.8307 5.0013C10.8307 4.54106 10.4576 4.16797 9.9974 4.16797C9.53716 4.16797 9.16406 4.54106 9.16406 5.0013C9.16406 5.46154 9.53716 5.83463 9.9974 5.83463C10.4576 5.83463 10.8307 5.46154 10.8307 5.0013Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9974 15.8333C10.4576 15.8333 10.8307 15.4603 10.8307 15.0001V8.33333C10.8307 7.8731 10.4576 7.5 9.9974 7.5C9.53716 7.5 9.16406 7.8731 9.16406 8.33333V15.0001C9.16406 15.4603 9.53716 15.8333 9.9974 15.8333Z' fill='white'/%3E%3C/svg%3E")
        center/contain no-repeat;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        height: 12px;
        background-color: var(--color-85);
        opacity: 0;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        width: 32px;
        left: -8px;
        margin-bottom: 7px;

        @include min_1025 {
          transition: opacity 0.3s ease-in-out;
          width: 100%;
          left: 0;
          margin-bottom: 12px;
        }
      }

      @include min_1025 {
        &:hover {
          ~ .info {
            opacity: 1;
          }
          &::after {
            opacity: 1;
          }
        }
      }

      @include max_1025 {
        &.active {
          ~ .info {
            display: block;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .info {
      padding: min_max(12, 16) min_max(16, 24);
      background-color: var(--color-85);
      color: var(--color-1);
      border-radius: 8px;
      width: 100%;

      @include min_1025 {
        pointer-events: none;
        position: absolute;
        bottom: 100%;
        left: calc(100% - min_max(8, 10));
        transform: translateX(-50%);
        opacity: 0;
        transition: 0.3s ease-in-out;
        width: min_max(300, 340);
        margin-bottom: 23px;
      }

      @include max_1025 {
        order: -1;
        display: none;
        margin-bottom: 10px;
      }
    }
  }

  .row {
    position: relative;
    display: flex;
  }

  &:hover {
    .border {
      border-color: var(--color-6);
    }
    .selected {
      background-color: #2d2a40;
    }
  }

  &.read {
    color: var(--color-secondary);
    .border {
      border: 2px dashed var(--color-6);
    }
  }

  .hidden {
    display: none;
  }

  &.hidden {
    display: none;
  }

  &.select {
    input {
      &:not(.search) {
        pointer-events: none;
      }
      &:focus {
        &::placeholder {
          color: var(--color-neutral-purple);
        }
      }
    }

    &.open {
      .list {
        display: block;
        // opacity: 1;
        // pointer-events: all;
      }

      .icon {
        transform: rotate(90deg) rotateY(180deg);
      }
    }

    .list {
      // position: absolute;
      // top: 100%;
      // left: 0;
      width: 100%;
      // padding-top: $__10;
      // z-index: 10;
      display: none;

      // opacity: 0;
      // pointer-events: none;
      // transition: opacity 0.3s ease-in-out;
    }

    .list_content {
      gap: $__6;
      padding: $__10;
      background-color: var(--color-9);

      // max-height: 15em;
      max-height: calc(min_max(33, 40) * 5 + $__6 * 4 + $__10 * 2);
      overflow: scroll;
      overflow-x: visible;

      .l {
        padding: $__8 $__24;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        flex: none;
        line-height: 150%;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          background-color: var(--color-8);
        }

        &:not(.sel) {
          span {
            color: var(--color-primary);
          }
        }

        &.sel {
          // opacity: 0.4;
          pointer-events: none;
          color: var(--color-neutral-purple);
          background-color: var(--color-9);
        }
      }
    }

    .border {
      border-color: transparent;
    }
  }
  &.checkbox {
    // display: flex;
    width: auto;

    cursor: pointer;

    .row {
      align-items: center;
      gap: $__10;
    }

    input {
      left: 100%;
      top: 100%;
      padding: 0;
      pointer-events: none;
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }

    .ico {
      width: $__24;
      height: $__24;
      border: 2px solid var(--color-7);
      transition: border 0.1s ease-in-out;
      flex: none;

      svg {
        width: 100%;
        height: auto;
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
      }
    }

    a {
      // transition: 0.3s ease-in-out;
      // text-decoration: underline;
      @include text-fill(
        radial-gradient(197.79% 141.42% at 0% 0%, #f12ef5 0%, #8749ed 59.38%, #8749ed 80.21%, #ba24d2 100%)
      );

      &:hover {
        @include text-fill(
          radial-gradient(285.38% 202.03% at 0% -42.86%, #f12ef5 0%, #8749ed 61.46%, #8749ed 80.73%, #ba24d2 100%)
        );
      }
    }

    &:hover {
      .ico {
        border-color: #433f57;
      }
    }

    &.active {
      .ico {
        border-color: var(--color-secondary);

        svg {
          opacity: 1;
        }
      }
    }
  }

  &.textarea {
    .max {
      color: var(--color-neutral-purple);

      &.done {
        color: var(--color-destructive-3);
      }
    }
  }

  &.password {
    .ico {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: $__24;
      right: $__20;
      z-index: 1;
      cursor: pointer;

      svg {
        width: 100%;
        height: auto;
        opacity: 0.4;
        transition: opacity 0.3s ease-in-out;
      }

      &.show {
        svg {
          opacity: 1;
        }
      }
    }
  }

  &.active {
    input,
    textarea {
      &:focus ~ .border {
        border-color: var(--color-secondary);
      }
    }
  }
  &.success {
    .border {
      border-color: var(--color-primary) !important;
    }
  }
  &.error {
    .name {
      color: var(--color-destructive-3);
      order: 2;
    }
    .border {
      border-color: var(--color-destructive-3) !important;
    }
    .selected {
      background: var(--color-destructive);
      color: var(--color-destructive-3);
    }
  }

  .border {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: $__r-16;
    transition: border 0.3s ease-in-out;
    border: 2px solid var(--color-8);
  }
  .selected {
    display: flex;
    width: 100%;
    border-radius: $__r-16;
    transition: background-color 0.3s ease-in-out;
    background-color: var(--color-9);
    cursor: pointer;
    position: relative;
    align-items: center;

    .icon {
      position: absolute;
      width: $__24;
      right: $__20;
      transform: rotate(90deg);
      transition: 0.3s ease-in-out;
      pointer-events: none;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  input,
  textarea {
    // all: unset;
    background-color: transparent;
    outline: none;
    width: 100%;
    padding: $__16 $__20;
    text-align: left;
    cursor: auto;
    border: none;
    border-radius: $__r-16;
    color: var(--color-white);
    line-height: 146%;

    &::placeholder {
      transition: 0.3s ease-in-out;
      color: var(--color-neutral-purple);
    }

    &:focus {
      &::placeholder {
        color: var(--color-white);
      }
    }
  }

  textarea {
    resize: vertical;

    &::-webkit-resizer {
      display: none;
    }
  }

  .resize {
    position: absolute;
    bottom: 7px;
    right: 7px;
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E%3Cpath d='M1 9L9 1M6 9L9 6' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
    opacity: 0.3;
    pointer-events: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.user_photo_choose {
  gap: $__14;
  width: 100%;

  .scroll-bar {
    background: var(--color-85);
    pointer-events: none !important;
    .scroll-track {
      background: var(--color-4);
      pointer-events: none;
    }
  }

  .avatars_scroller {
    gap: $__16;
    display: flex;
    flex-wrap: wrap;
    // @include min_1025 {
    //   max-height: calc(min_max(44, 56) * 3 + $__16 * 2);
    //   overflow: scroll;
    //   overflow-x: visible;
    // }
  }

  .avatars {
    position: relative;

    img {
      pointer-events: none;
      transition: 0.2s ease-in-out;
    }
  }

  .icon {
    width: min_max(44, 56);
    aspect-ratio: 1/1;
    border: 2px solid transparent;
    cursor: pointer;

    &.active {
      border-color: var(--color-primary);
    }
    &.current {
      border-color: var(--color-secondary);
      position: relative;

      .close_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
        opacity: 0;
        z-index: 2;
      }

      &:hover {
        .close_icon {
          opacity: 1;
        }

        img {
          opacity: 0.5;
        }
      }
    }
  }

  .block {
    background-color: #106b9d66;
    position: relative;

    .icon_block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 5C5 2.23858 7.23858 0 10 0H14C16.7614 0 19 2.23858 19 5C19 5.55228 18.5523 6 18 6C17.4477 6 17 5.55228 17 5C17 3.34315 15.6569 2 14 2H10C8.34315 2 7 3.34315 7 5V8H19C21.7614 8 24 10.2386 24 13V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V13C0 10.2386 2.23858 8 5 8V5ZM5 10C3.34315 10 2 11.3431 2 13V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V13C22 11.3431 20.6569 10 19 10H5ZM12 14C12.5523 14 13 14.4477 13 15V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V15C11 14.4477 11.4477 14 12 14Z' fill='white'/%3E%3C/svg%3E")
        center/auto no-repeat;
      pointer-events: none;
    }

    .on_hover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      cursor: pointer;
    }
  }
}

.hidden_recaptcha {
  position: absolute;
}
