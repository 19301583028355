:root {
    /* Default */
    /* colors */
    --color-white: #ffffff;
    --color-black: #000000;
    --color-neutral-purple: #83809c;
    --color-popups: #252237;
    --color-page-bg: #080611;
    --color-footer: #040309;
    --color-tertiary: #312953;

    --color-primary: #b9ff54;
    --color-secondary: #8840e6;
    --color-tertiary: #362e5c;
    --color-destructive: #450816;
    --color-destructive-2: #7a0e28;
    --color-destructive-3: #dc1947;
    --color-05: #adabbd;
    --color-0: #b4b3c2;
    --color-1: #9c9ab0;
    --color-15: #8d8aa4;
    --color-2: #7a7796;
    --color-3: #64627f;
    --color-4: #4e4b68;
    --color-5: #45425c;
    --color-6: #3b3850;
    --color-7: #322f45;
    --color-85: #262437;
    --color-8: #2c293e;
    --color-95: #191627;
    --color-9: #1f1c2e;
    --color-10-transparent: #78709614;
    // --logo-gradient: linear-gradient(90deg, #6bd388 -0.93%, #e9f52e 101.24%);
    --logo-gradient: linear-gradient(
        161deg,
        #82cb19 1.86%,
        #35c7c7 23.04%,
        #08b2f1 34.68%,
        #824bf2 50.97%,
        #9040f2 58.4%,
        #8549f2 65.87%,
        #08b2f1 86.02%
    );

    --button-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        radial-gradient(
            166.44% 141.42% at 0% 0%,
            rgb(0, 255, 102) 0%,
            rgb(0, 255, 102) 0.01%,
            rgb(58, 231, 168) 17.71%,
            rgb(44, 197, 218) 31.77%,
            rgb(59, 151, 233) 47.66%,
            rgb(102, 100, 255) 54.69%,
            rgb(135, 73, 237) 66.15%,
            rgb(106, 83, 243) 79.32%,
            rgb(56, 136, 255) 87.5%,
            rgb(31, 235, 235) 100%
        );
    --fn: linear(
        0 0%,
        0 2.27%,
        0.02 4.53%,
        0.04 6.8%,
        0.06 9.07%,
        0.1 11.33%,
        0.14 13.6%,
        0.25 18.15%,
        0.39 22.7%,
        0.56 27.25%,
        0.77 31.8%,
        1 36.35%,
        0.89 40.9%,
        0.85 43.18%,
        0.81 45.45%,
        0.79 47.72%,
        0.77 50%,
        0.75 52.27%,
        0.75 54.55%,
        0.75 56.82%,
        0.77 59.1%,
        0.79 61.38%,
        0.81 63.65%,
        0.85 65.93%,
        0.89 68.2%,
        1 72.7%,
        0.97 74.98%,
        0.95 77.25%,
        0.94 79.53%,
        0.94 81.8%,
        0.94 84.08%,
        0.95 86.35%,
        0.97 88.63%,
        1 90.9%,
        0.99 93.18%,
        0.98 95.45%,
        0.99 97.73%,
        1 100%
    );
}

// Paddings & Margins
$__156: min_max(140, 156);
$__140: min_max(136, 140);
$__136: min_max(120, 136);
$__120: min_max(100, 120);
$__100: min_max(80, 100);
$__80: min_max(60, 80);
$__60: min_max(56, 60);
$__56: min_max(50, 56);
$__50: min_max(46, 50);
$__46: min_max(40, 46);
$__40: min_max(36, 40);
$__36: min_max(34, 36);
$__34: min_max(32, 34);
$__32: min_max(24, 32);
$__24: min_max(20, 24);
$__20: min_max(16, 20);
$__16: min_max(14, 16);
$__14: min_max(12, 14);
$__12: min_max(10, 12);
$__10: min_max(8, 10);
$__8: min_max(6, 8);
$__6: min_max(4, 6);
$__4: min_max(2, 4);
$__2: min_max(1, 2);

// Radius
$__r-6: min_max(2, 6);
$__r-8: min_max(4, 8);
$__r-10: min_max(6, 10);
$__r-12: min_max(8, 12);
$__r-14: min_max(10, 14);
$__r-16: min_max(12, 16);
$__r-18: min_max(14, 18);
$__r-20: min_max(16, 20);

$mobile_14: 14px;
$fn: linear(
    0 0%,
    0 2.27%,
    0.02 4.53%,
    0.04 6.8%,
    0.06 9.07%,
    0.1 11.33%,
    0.14 13.6%,
    0.25 18.15%,
    0.39 22.7%,
    0.56 27.25%,
    0.77 31.8%,
    1 36.35%,
    0.89 40.9%,
    0.85 43.18%,
    0.81 45.45%,
    0.79 47.72%,
    0.77 50%,
    0.75 52.27%,
    0.75 54.55%,
    0.75 56.82%,
    0.77 59.1%,
    0.79 61.38%,
    0.81 63.65%,
    0.85 65.93%,
    0.89 68.2%,
    1 72.7%,
    0.97 74.98%,
    0.95 77.25%,
    0.94 79.53%,
    0.94 81.8%,
    0.94 84.08%,
    0.95 86.35%,
    0.97 88.63%,
    1 90.9%,
    0.99 93.18%,
    0.98 95.45%,
    0.99 97.73%,
    1 100%
);
