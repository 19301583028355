// @include font-face("Fixel Thin", "FixelDisplay-100_Thin", 100, normal, woff2);
// @include font-face("Fixel ExtraLight", "FixelDisplay-200_ExtraLight", 200, normal, woff2);
// @include font-face("Fixel Light", "FixelDisplay-300_Light", 300, normal, woff2);
@include font-face("Fixel Regular", "FixelDisplay-400_Regular", 400, normal, woff2); //
@include font-face("Fixel Medium", "FixelDisplay-500_Medium", 500, normal, woff2); //
@include font-face("Fixel SemiBold", "FixelDisplay-600_SemiBold", 600, normal, woff2); //
@include font-face("Fixel Bold", "FixelDisplay-700_Bold", 700, normal, woff2); //
// @include font-face("Fixel ExtraBold", "FixelDisplay-800_ExtraBold", 800, normal, woff2);
// @include font-face("Fixel Black", "FixelDisplay-900_Black", 900, normal, woff2);

:root {
  --fixel_thn: "Fixel Thin", sans-serif;
  --fixel_elg: "Fixel ExtraLight", sans-serif;
  --fixel_lig: "Fixel Light", sans-serif;
  --fixel_reg: "Fixel Regular", sans-serif;
  --fixel_med: "Fixel Medium", sans-serif;
  --fixel_sbd: "Fixel SemiBold", sans-serif;
  --fixel_bld: "Fixel Bold", sans-serif;
  --fixel_ebd: "Fixel ExtraBold", sans-serif;
  --fixel_blk: "Fixel Black", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

@mixin fixel {
  font-family: var(--fixel_med);
  font-size: min_max(14, 16);
  font-weight: 500;
  line-height: 126%;
  letter-spacing: 0;
}

@mixin fixel_h6 {
  font-family: var(--fixel_bld);
  font-size: min_max(40, 56);
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0;
}
@mixin fixel_60 {
  font-family: var(--fixel_bld);
  font-size: min_max(42, 60);
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0;
}
@mixin fixel_50 {
  font-family: var(--fixel_bld);
  font-size: min_max(26, 50); // updated
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0;
}
@mixin fixel_42 {
  font-family: var(--fixel_bld);
  font-size: min_max(24, 42);
  font-weight: 700;
  line-height: 134%;
  letter-spacing: 0;
}
@mixin fixel_36 {
  font-family: var(--fixel_sbd);
  font-size: min_max(18, 36);
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0;
}
@mixin fixel_26 {
  font-family: var(--fixel_bld);
  font-size: min_max(18, 26);
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.02em;
}
@mixin fixel_22 {
  font-family: var(--fixel_sbd);
  font-size: min_max(16, 22);
  font-weight: 600;
  line-height: 146%;
  letter-spacing: 0.02em;
}
@mixin fixel_16_bl {
  font-family: var(--fixel_bld);
  font-size: min_max(14, 16);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.02em;
}
@mixin fixel_16_sbl {
  font-family: var(--fixel_sbd);
  font-size: min_max(14, 16);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.02em;
}
@mixin fixel_16_md {
  font-family: var(--fixel_med);
  font-size: min_max(14, 16);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.02em;
}
@mixin fixel_16_reg {
  font-family: var(--fixel_reg);
  font-size: min_max(14, 16);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.02em;
}
@mixin fixel_14 {
  font-family: var(--fixel_med);
  font-size: min_max(12, 14);
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02em;

  @include max_1025 {
    font-size: 14px;
  }
}

.fixel {
  @include fixel;

  &_h6 {
    @include fixel_h6;
  }
  &_60 {
    @include fixel_60;
  }
  &_50 {
    @include fixel_50;
  }
  &_42 {
    @include fixel_42;
  }
  &_36 {
    @include fixel_36;
  }
  &_26 {
    @include fixel_26;
  }
  &_22 {
    @include fixel_22;
  }
  &_16_bl {
    @include fixel_16_bl;
  }
  &_16_sbl {
    @include fixel_16_sbl;
  }
  &_16_md {
    @include fixel_16_md;
  }
  &_16_reg {
    @include fixel_16_reg;
  }
  &_14 {
    @include fixel_14;
  }
}

h1 {
  @include fixel_50();
}
h2 {
  @include fixel_42();
}
