html,
body {
  @include no-scrollbar;
}

body {
  color: var(--color-white);
  @include fixel_16_reg;
  width: 100%;
  // background-color: var(--color-page-bg);
  background: #0f0d1a;
}

.fantico_logo {
  .clip_logo {
    width: min_max(106, 200);
    // height: min_max(16, 30);
    aspect-ratio: 20/3;
    clip-path: url("#funtico_logo");
    background: var(--logo-gradient);
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.lazy-load-image-background {
  display: flex;
}

section {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.arrow_nav {
  position: absolute;
  bottom: 0;
  width: 24px;
  z-index: 1;

  @include min_1025 {
    bottom: auto;
    top: 50%;
    width: $__40;
  }

  svg {
    width: 100%;
    height: auto;
    pointer-events: none;
  }

  &.l {
    left: 0;
    transform: rotate(180deg);

    @include min_1025 {
      left: auto;
      right: calc(100% + min_max(10, 12));
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &.r {
    right: 0;

    @include min_1025 {
      transform: translateY(-50%);
      left: calc(100% + min_max(10, 12));
    }
  }
}

.section_content {
  width: 100%;
  padding: 0 $mobile_14;
  max-width: 768px;

  @include min_1025 {
    // width: 90%;
    padding: 0;
    max-width: 1920px;
  }
}

.game_timer {
  p {
    color: var(--color-neutral-purple);
  }

  .time {
    gap: 8px;

    @include min_1025 {
      gap: 4px;
    }
  }

  .col {
    padding: min_max(6, 8) min_max(8, 10);
    background-color: var(--color-95);
    gap: 4px;
  }
}

.socials {
  gap: $__32;
  @include min_1025 {
    gap: $__24;

    svg {
      path {
        fill: var(--color-neutral-purple);
        transition: 0.3s ease-in-out;
      }
    }

    .network {
      &:hover {
        svg path {
          fill: var(--color-white);
        }
      }
    }
  }
  .network {
    width: $__24;

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.svg_hidden {
  position: absolute;
  width: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.cover {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.close_icon {
  pointer-events: none;
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.57806 3.57757C3.9035 3.25214 4.43114 3.25214 4.75657 3.57757L10.0007 8.82165L15.2447 3.57757C15.5702 3.25214 16.0978 3.25214 16.4232 3.57757C16.7487 3.90301 16.7487 4.43065 16.4232 4.75609L11.1792 10.0002L16.4232 15.2442C16.7487 15.5697 16.7487 16.0973 16.4232 16.4228C16.0978 16.7482 15.5702 16.7482 15.2447 16.4228L10.0007 11.1787L4.75657 16.4228C4.43114 16.7482 3.9035 16.7482 3.57806 16.4228C3.25263 16.0973 3.25263 15.5697 3.57806 15.2442L8.82214 10.0002L3.57806 4.75609C3.25263 4.43065 3.25263 3.90301 3.57806 3.57757Z' fill='white'/%3E%3C/svg%3E")
    center/contain no-repeat;
}
.back_icon {
  gap: 10px;

  &::before {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289C17.0976 3.68342 17.0976 4.31658 16.7071 4.7071L9.41421 12L16.7071 19.2929C17.0976 19.6834 17.0976 20.3166 16.7071 20.7071C16.3166 21.0976 15.6834 21.0976 15.2929 20.7071L7.29289 12.7071Z' fill='white'/%3E%3C/svg%3E");
  }
}

.df {
  display: flex;
}
.fw {
  flex-wrap: wrap;
}
.fd-c {
  flex-direction: column;
}
.ai-c {
  align-items: center;
}
.ai-st {
  align-items: flex-start;
}
.ai-en {
  align-items: flex-end;
}
.as-c {
  align-self: center;
}
.as-fs {
  align-self: flex-start;
}
.as-fe {
  align-self: flex-end;
}
.jc-c {
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}

.br {
  &-6 {
    border-radius: $__r-6;
    overflow: hidden;
  }
  &-8 {
    border-radius: $__r-8;
    overflow: hidden;
  }
  &-10 {
    border-radius: $__r-10;
    overflow: hidden;
  }
  &-12 {
    border-radius: $__r-12;
    overflow: hidden;
  }
  &-14 {
    border-radius: $__r-14;
    overflow: hidden;
  }
  &-16 {
    border-radius: $__r-16;
    overflow: hidden;
  }
  &-18 {
    border-radius: $__r-18;
    overflow: hidden;
  }
  &-20 {
    border-radius: $__r-20;
    overflow: hidden;
  }
}

@include min_1025 {
  .pd {
    &-r-sm {
      padding-right: min_max(70, 136);
    }
    &-l-sm {
      padding-left: min_max(70, 136);
    }
    &-lr-sm {
      padding-right: min_max(40, 136);
      padding-left: min_max(40, 136);
    }

    &-r-md {
      padding-right: min_max(40, 156);
    }
    &-l-md {
      padding-left: min_max(40, 156);
    }
    &-lr-md {
      padding-right: min_max(40, 156);
      padding-left: min_max(40, 156);
    }

    &-r-lg {
      padding-right: min_max(40, 292);
    }
    &-l-lg {
      padding-left: min_max(40, 292);
    }
    &-lr-lg {
      padding-right: min_max(40, 292);
      padding-left: min_max(40, 292);
    }

    &-t-lg {
      padding-top: min_max(40, 80);
    }
    &-b-lg {
      padding-bottom: min_max(40, 80);
    }
    &-t-md {
      padding-top: min_max(30, 60);
    }
    &-b-md {
      padding-bottom: min_max(30, 60);
    }
    &-tb-md {
      padding-top: min_max(30, 60);
      padding-bottom: min_max(30, 60);
    }
    &-tb-lg {
      padding-top: min_max(40, 80);
      padding-bottom: min_max(40, 80);
    }
  }

  .w {
    &-lg {
      max-width: 1608px;
    }
    &-md {
      max-width: 1336px;
    }
    &-sm {
      max-width: 1064px;
    }
  }
}

.mr {
  &-t-50 {
    margin-top: min_max(32,50);
  }
  &-t-46 {
    margin-top: $__46;
  }
  &-t-24 {
    margin-top: $__24;
  }
  &-t-16 {
    margin-top: $__16;
  }
  &-t-12 {
    margin-top: $__12;
  }
  &-t-10 {
    margin-top: $__10;
  }
  &-t-8 {
    margin-top: $__8;
  }
}

.swiper {
  display: flex;
  flex-direction: column;
}

.swiper-scrollbar {
  width: 100%;
  background-color: var(--color-9);
  height: 4px;
  margin-top: $__24;

  &-lock {
    display: none;
  }
  &-drag {
    background-color: var(--color-neutral-purple);
    height: 4px;
    border-radius: 4px;
  }
}

[aria-current="page"] {
  pointer-events: none;
}

.grecaptcha-badge {
  z-index: 10002;
}
